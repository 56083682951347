import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/LadingPage";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="*" element={<Navigate replace to="/" />}></Route>
    </Routes>
  );
}
