import styled from "styled-components";

interface ContainerProps {
  margin?: { marginMobile: number; marginDesk: number };
}

export const Container = styled.h2<ContainerProps>`
  font-size: 1.75rem;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--brand-color);
  margin-bottom: ${({ margin }) =>
    margin ? `${margin.marginMobile}rem` : "2rem"};

  @media (min-width: 1024px) {
    margin-bottom: ${({ margin }) =>
      margin ? `${margin.marginDesk}rem` : "4.5rem"};
    font-size: 3.2rem;
  }
`;
