import { useState } from "react";
import {
  StyledHeader,
  LogoWrapper,
  MenuList,
  MenuItem,
  MobileBtn,
  Button,
} from "./styles";
import logo from "../../assets/img/logo.png";
import menuIcon from "../../assets/img/header-btn.png";
import arrow from "../../assets/img/arrow.svg";

interface HeaderProps {
  onClick: () => void;
}

export default function Header({ onClick }: HeaderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function toogleMenu() {
    setIsOpen(!isOpen);
  }
  return (
    <StyledHeader>
      <nav>
        <LogoWrapper>
          <a
            href={`https://kenzie.com.br/${window.location.search}`}
            rel="noreferrer"
          >
            <img src={logo} alt="Kenzie Academy" width="222" height="55" />
          </a>
          <MobileBtn onClick={toogleMenu}>
            <img src={menuIcon} alt="Ícone Menu" width="32" height="32" />
          </MobileBtn>
        </LogoWrapper>
        <MenuList open={isOpen}>
          <li>
            <MenuItem
              href={`https://kenzie.com.br/quem-somos.html${window.location.search}`}
              rel="noreferrer"
            >
              Quem somos
            </MenuItem>
          </li>
          <li>
            <MenuItem
              href={`https://kenzie.com.br/curriculo.html${window.location.search}`}
              rel="noreferrer"
            >
              Formação
            </MenuItem>
          </li>
          <li>
            <MenuItem
              href={`https://kenzie.com.br/pagamento.html${window.location.search}`}
              rel="noreferrer"
            >
              Pagamento
            </MenuItem>
          </li>
          <li>
            <MenuItem
              href={`https://parceiras.kenzie.com.br${window.location.search}`}
              rel="noreferrer"
              className="active"
            >
              Para empresas
            </MenuItem>
          </li>
          <li>
            <MenuItem
              href={`https://kenzie.com.br/faq.html${window.location.search}`}
              rel="noreferrer"
            >
              FAQ
            </MenuItem>
          </li>
          <li>
            <MenuItem
              href={`https://kenzie.com.br/blog/${window.location.search}`}
              rel="noreferrer"
            >
              Blog
            </MenuItem>
          </li>
        </MenuList>
        <Button onClick={onClick}>
          Quero ser parceira
          <img src={arrow} alt="Seta" width="56" height="16" />
        </Button>
      </nav>
    </StyledHeader>
  );
}
