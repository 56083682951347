import styled from "styled-components";

interface TitleProps {
  color: string;
}

export const Wrapper = styled.div`
  section {
    padding: 2.5rem 0;
  }

  section:first-child {
    background-color: #e1ebff;
  }

  section:last-child {
    background-color: #0a1e32;
    color: #ffffff;
  }

  @media (min-width: 1024px) {
    background: linear-gradient(90deg, #e1ebff 50%, #0a1e32 50%);

    section {
      width: 50%;
      padding: 4.5rem 0;
    }

    section:last-child {
      div {
        margin-left: auto;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 85%;
    flex-direction: row;
  }
`;

export const Content = styled.div`
  width: 85%;
  margin: 0 auto;

  p {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  p:last-of-type {
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  img {
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 453px;
    margin: 0;

    p {
      line-height: 1.8;
      margin-bottom: 2rem;
    }

    p:nth-of-type(2) {
      flex-grow: 1;
    }

    p:not(:last-of-type) {
      font-size: 1rem;
    }

    p:last-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export const Title = styled.h2<TitleProps>`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.4;
  color: ${({ color }) => color};
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    font-size: 2.75rem;
    margin-bottom: 2rem;
  }

  span {
    font-size: 1rem;

    @media (min-width: 360px) {
      font-size: 1.125rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }
`;
