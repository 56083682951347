import styled from "styled-components";

interface MenuProps {
  open: boolean;
}

export const StyledHeader = styled.header`
  margin: 0 auto;
  height: 6.25rem;
  background-color: var(--white);
  position: fixed;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  z-index: 100;

  @media (min-width: 1024px) {
    nav {
      display: flex;
      height: 100%;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  padding-bottom: 1rem;

  @media (min-width: 1024px) {
    width: 20%;
    margin-right: auto;
  }

  @media (min-width: 1024px) and (max-width: 1400px) {
    a img {
      width: 9rem;
      height: auto;
    }
  }

  @media (min-width: 1400px) {
    padding-left: 2rem;
  }

  @media (min-width: 1500px) {
    padding-left: 3rem;
  }
`;

export const MenuList = styled.ul<MenuProps>`
  overflow: hidden;
  background-color: var(--white);
  height: ${({ open }) => (open ? "14rem" : 0)};
  padding: 0 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: height 0.3s ease-in-out;

  li + li {
    margin-top: 1.1rem;
  }

  li:last-child {
    margin-bottom: 1.1rem;
  }

  @media (min-width: 1024px) {
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 0;
    margin-left: 2.5rem;

    li {
      text-align: center;
    }

    li + li {
      margin-top: 0;
    }

    li:last-child {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  @media (min-width: 1400px) {
    gap: 2.5rem;
    margin-left: 4.5rem;
  }
`;

export const MenuItem = styled.a`
  position: relative;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    height: 2px;
    bottom: -4px;
    left: 2px;
    right: 0;
    background-color: var(--brand-color);
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before,
  &:focus::before {
    transform: scaleX(0.6);
  }

  &.active {
    color: var(--brand-color);
  }
`;

export const MobileBtn = styled.button`
  outline: none;
  background-color: var(--white);

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: none;

  @media (min-width: 1024px) {
    width: 28.625rem;
    max-width: 430px;
    margin-left: auto;
    background-color: var(--brand-color);
    color: var(--white);
    font-weight: bold;
    font-size: 1.125rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: background-color 0.5s;

    &:hover,
    &:focus {
      background-color: var(--highlight-color);
    }

    img {
      width: 3.75rem;
      transition: transform 0.5s;
    }

    &:hover img,
    &:focus img {
      transform: scale(1.1);
    }
  }

  @media (min-width: 1400px) {
    font-size: 1.125rem;
    min-width: 28.625rem;
  }
`;
