import { Container } from "./styles";

interface HowItWWWorksCardProps {
  paragraph: string;
  cardNumber: string;
  numberColor: string;
  paragraphColor: string;
  backgroundColor: string;
}
export default function HowItWorksCard({
  paragraph,
  cardNumber,
  numberColor,
  paragraphColor,
  backgroundColor,
}: HowItWWWorksCardProps) {
  return (
    <Container
      paragraphColor={paragraphColor}
      numberColor={numberColor}
      backgroundColor={backgroundColor}
    >
      <span>{cardNumber}</span>
      <p>{paragraph}</p>
    </Container>
  );
}
