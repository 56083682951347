import { Wrapper, Container, Content, Title } from "./styles";
import boticario from "../../assets/img/logo-boticario.png";
import ifood from "../../assets/img/logo-ifood.png";

export default function ProjectsSection() {
  return (
    <Wrapper>
      <Container>
        <section>
          <Content
            data-aos="fade-right"
            data-aos-duration="700"
            data-aos-easing="ease-out-cubic"
          >
            <Title color="#0000ff">
              <span>Seja mais que um parceiro:</span>
              <br />
              Bolsa integral
            </Title>
            <p>
              Imagine capacitar dezenas de desenvolvedores que sejam alinhados
              com as necessidades da sua empresa, extremamente autônomos, donos
              de uma alta capacidade de aprendizado e prontos para entrar no seu
              time em apenas 6 meses. A Parceria Bolsa Integral traz uma
              excelente oportunidade de formar pessoas qualificadas para sua
              empresa.
            </p>
            <p>
              Tenha um impacto positivo no mercado, mostrando como a sua marca
              pode transformar vidas!
            </p>
            <p>Quem optou por essa modalidade:</p>
            <img
              src={boticario}
              alt="Grupo Boticário"
              width="118"
              height="59"
            />
          </Content>
        </section>
        <section>
          <Content
            data-aos="fade-left"
            data-aos-duration="700"
            data-aos-easing="ease-out-cubic"
          >
            <Title color="#ffffff">
              <span>Seja mais que um parceiro:</span>
              <br />
              Auxílio mensal
            </Title>
            <p>
              Ajude centenas de estudantes a focar no aprendizado e ter um
              aproveitamento ainda maior da formação.
            </p>
            <p>
              Com o auxílio estudo, os estudantes recebem um valor mensal para
              ajudar nas necessidades básicas enquanto desenvolvem suas
              habilidades em tecnologia.{" "}
            </p>
            <p>Quem optou por essa modalidade:</p>
            <img src={ifood} alt="iFood" width="80" height="59" />
          </Content>
        </section>
      </Container>
    </Wrapper>
  );
}
