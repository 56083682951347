import styled from "styled-components";

export const Container = styled.section`
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding-top: 4.5rem;
  }

  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2.5rem;

    li {
      border: 1px solid #eaeaea;
      width: 105px;
      height: 89px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
    }

    img {
      object-fit: contain;
      max-width: 100%;
    }

    @media (min-width: 1024px) {
      width: 85%;
      margin: 0px auto;
      max-width: 1112px;
      margin-bottom: 4.5rem;

      li {
        width: 139px;
        height: 120px;

        img {
          width: 118px;
          height: 59px;
        }
      }
    }
  }

  p {
    width: 85%;
    margin: 0px auto;
    max-width: var(--max-width);
    padding-bottom: 2.5rem;
    text-align: center;

    @media (min-width: 1024px) {
      padding-bottom: 4.5rem;
    }
  }
`;

export const TitleContainer = styled.div`
  width: 85%;
  margin: 0px auto;
  max-width: var(--max-width);
`;

export const Loader = styled.div`
  width: 6em;
  height: 6em;
  border: 0.8em solid #0000ff9c;
  border-left: 0.8em solid var(--brand-color);
  border-radius: 50%;
  animation: spin 1.1s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
