import { Container, ContentContainer, List, Picture } from "./styles";
import Title from "../Title";
import dev from "../../assets/img/dev.png";
import devMobile from "../../assets/img/dev-mobile.png";

export default function DevsSection() {
  return (
    <Container
      data-aos="fade-right"
      data-aos-duration="700"
      data-aos-easing="ease-out-cubic"
    >
      <Title>Por que contratar devs da Kenzie?</Title>
      <ContentContainer>
        <List>
          <li>
            Programadores(as) da Kenzie têm{" "}
            <strong>facilidade em trabalhar em equipe</strong>: Priorizamos um
            ensino através de projetos práticos e em equipe.
          </li>
          <li>
            <strong>Flexibilidade</strong>: Nossa metodologia simula o dia a dia
            de uma empresa e isso inclui se adaptar a mudanças rapidamente.
          </li>
          <li>
            <strong>Uso de metodologias ágeis</strong> no dia a dia e no
            desenvolvimento de projetos para entregas mais rápidas e assertivas.
          </li>
          <li>
            <strong>Focados no aprender a aprender contínuo</strong>:
            Desenvolvedores(as) da Kenzie têm a mentalidade que sempre precisam
            se aprimorar.
          </li>
        </List>
        <Picture>
          <source media="(min-width: 1300px)" srcSet={dev} />
          <img src={devMobile} alt="Dev Kenzie" height="170" width="335" />
        </Picture>
      </ContentContainer>
    </Container>
  );
}
