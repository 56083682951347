import styled, { css } from "styled-components";

interface ContainerProps {
  image: string;
  imageLarge?: string;
}

export const Wrapper = styled.section`
  padding-top: 6.25rem;
`;

export const BackgroundDiv = styled.div<ContainerProps>`
  background: url(${({ image }) => image}) no-repeat top right;
  background-size: cover;

  @media (min-width: 420px) {
    ${(props) =>
      !!props.imageLarge &&
      css`
        background-image: url(${props.imageLarge});
      `}
  }
`;

export const Container = styled.div`
  width: 85%;
  max-width: var(--max-width);
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
    height: 670px;
    display: flex;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  color: var(--white);

  p {
    font-size: 1.175rem;
    margin-bottom: 2rem;
    font-weight: 300;
    max-width: 270px;

    @media (min-width: 370px) {
      font-size: 1.35rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.475rem;
      margin-bottom: 2.5rem;
      max-width: 540px;
    }
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  max-width: 280px;

  @media (min-width: 370px) {
    font-size: 1.65rem;
    max-width: 320px;
  }

  @media (min-width: 1024px) {
    font-size: 3.2rem;
    margin-bottom: 2rem;
    max-width: 600px;
  }
`;

export const Subtitle = styled.h2`
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  max-width: 320px;

  @media (min-width: 370px) {
    font-size: 1.65rem;
  }

  @media (min-width: 1024px) {
    font-size: 3.2rem;
    margin-bottom: 2rem;
  }
`;

export const Button = styled.button`
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5);
  border: 3px solid #ffffff;
  border-radius: var(--btn-border-radius);
  padding: 1.065rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 390px;
  transition: var(--transition);

  &:hover,
  &:focus {
    background-color: var(--brand-color);
    border-color: transparent;
  }

  img {
    margin-left: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.375rem;
    justify-content: center;
    max-width: 448px;
  }
`;
