import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 2.5rem 0;
  background-color: var(--brand-color);

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const Container = styled.div`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (min-width: 1305px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    color: #fff;
    font-size: 1.375rem;
    margin: 1rem 0;

    @media (min-width: 1024px) {
      font-size: 1.625rem;
    }
  }

  p.contact {
    max-width: 17rem;
    margin: 1rem 0 2rem;

    @media (min-width: 500px) {
      max-width: unset;
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;

  @media (min-width: 1024px) {
    font-size: 2.75rem;
  }
`;

export const Content = styled.div`
  @media (min-width: 1305px) {
    width: 50%;
  }
`;

export const Form = styled.div`
  @media (min-width: 1305px) {
    width: 50%;
    max-width: 458px;
  }
`;
