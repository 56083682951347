import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

html, body, div, p, header, footer, main, section, nav, ul, li, a, button, input, img, article, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    border: none;
}

:root {
    --text-color: #2d2d2d;
    --brand-color: #0000ff;
    --brand-dark-color: #001e32;
    --highlight-color: #3532ff;
    --white: #fff;
    --soft-white: #f5f5f5;
    --max-width: 1120px;
    --border-radius: 20px;
    --btn-border-radius: 2px;
}

html {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
     scroll-behavior: smooth;
}

body, button, a, input {
    font-family: "Montserrat", sans-serif;
    color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
    font-family: "JetBrains Mono", monospace;
}

p {
    line-height: 1.4;
}

button {
    cursor: pointer;
}
`;
