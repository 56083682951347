import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { GlobalStyle } from "./styles/global";
import Router from "./routes";

function App() {
  window.location.href = "https://www.kenzie.com.br/parceiras";
  useEffect(() => {
    AOS.init({
      once: true,
      disable: function () {
        var maxWidth = 576;
        return window.innerWidth < maxWidth;
      },
    });
  });

  return (
    <>
      <GlobalStyle />
      <Router />
    </>
  );
}

export default App;
