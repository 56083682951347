import styled from "styled-components";

export const Container = styled.section`
  width: 85%;
  margin: 0px auto;
  max-width: var(--max-width);
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding-top: 4.5rem;
  }
`;

export const Content = styled.div`
  ul {
    li + li {
      margin-top: 1.5rem;
    }

    @media (min-width: 1024px) {
      display: flex;
      gap: 1rem;

      li + li {
        margin-top: 0;
      }
    }
  }

  button {
    max-width: 44.5rem;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    padding: 0.8rem;
    color: var(--brand-color);
    border: 3px solid var(--brand-color);
    border-radius: var(--btn-border-radius);
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin: 2.5rem auto 0;

    svg path {
      transition: all 0.3s ease;
    }
    &:hover,
    &:focus {
      background-color: var(--brand-color);
      color: var(--white);
    }
    &:hover svg path,
    &:focus svg path {
      fill: var(--white);
    }

    @media (min-width: 340px) {
      font-size: 1.175rem;
    }
    @media (min-width: 390px) {
      font-size: 1.375rem;
    }
    @media (min-width: 1024px) {
      margin: 4.5rem auto 0;
    }
  }
`;
