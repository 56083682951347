import styled from "styled-components";

export const Container = styled.section`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding-top: 4.5rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1300px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const List = styled.ul`
  font-size: 1.25rem;
  padding-left: 20px;

  li {
    list-style-type: disc;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1300px) {
    width: 48%;
    font-size: 1rem;

    li {
      line-height: 1.8;
    }
  }
`;

export const Picture = styled.picture`
  max-width: fit-content;

  img {
    object-fit: contain;
    max-width: 100%;
  }

  @media (min-width: 1300px) {
    width: 48%;

    img {
      width: 528px;
      height: 427px;
    }
  }
`;
