import styled from "styled-components";

export const Container = styled.div``;

export const Form = styled.form`
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: 700;
    border: 3px solid #ffffff;
    width: 100%;
    height: 4rem;
    padding: 1rem 2rem;
    border-radius: var(--btn-border-radius);
    outline: none;
    color: var(--white);
    background-color: transparent;
    transition: all 0.3s ease-in-out;

    img {
      margin-left: 1rem;
    }

    &:hover,
    &:focus {
      background-color: var(--brand-dark-color);
      border-color: transparent;
    }

    @media (min-width: 360px) {
      font-size: 1.375rem;
    }

    @media (min-width: 1024px) {
      justify-content: center;
      font-size: 1.375rem;
    }
  }
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  height: 4rem;
  background-color: #fff;
  border-radius: 5px;

  svg {
    color: #909090;
    font-size: 2rem;
    margin-left: 0.5rem;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 1.375rem;
    padding: 1rem;
    border-radius: 0 5px 5px 0;
    border: none;
    outline: none;

    &::placeholder {
      color: #909090;
    }
  }
`;

export const ErrorMessage = styled.div`
  margin: 1rem 0;
  color: #fff;
  text-align: center;
`;
