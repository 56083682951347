import styled from "styled-components";

export const Container = styled.section`
  width: 85%;
  margin: 0px auto;
  max-width: var(--max-width);
  padding-top: 2.5rem;

  @media (min-width: 1024px) {
    padding-top: 4.5rem;
  }
`;

export const Content = styled.div`
  ul {
    li {
      display: flex;
      gap: 1rem;
      color: var(--text-color);
      font-size: 1.25rem;
    }

    li + li {
      margin-top: 1.5rem;
    }

    @media (min-width: 1024px) {
      li {
        gap: 1rem;
      }
    }
  }
`;
