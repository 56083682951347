import Title from "../Title";
import { Container, Loader, TitleContainer } from "./styles";
import { useEffect, useState } from "react";
import { PartnerProps } from "../../models/partner";
import axios from "axios";

export default function PartnersSection() {
  const [partners, setPartners] = useState<PartnerProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPartners = async () => {
    let output: PartnerProps[] = [];
    const pageCount = await axios
      .get(`https://kenzie-pages-api.herokuapp.com/api/partners?populate=*`)
      .then((r) => r.data.meta.pagination.pageCount);
    for (let counter = 1; counter <= pageCount; counter++) {
      const res = await axios
        .get(
          `https://kenzie-pages-api.herokuapp.com/api/partners?populate=*&pagination[page]=${counter}`
        )
        .then((r) => r.data.data);
      output = [...output, ...res];
    }
    setPartners(output);
    setIsLoading(false);
  };

  useEffect(() => {
    getPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Title>Quem já está conosco</Title>
      </TitleContainer>
      <ul>
        {isLoading && <Loader />}
        {partners.map(
          (partner) =>
            partner.attributes.onTheTop && (
              <li key={partner.id}>
                <img
                  src={partner.attributes.image.data.attributes.url}
                  alt={`Logo da empresa ${partner.attributes.name}`}
                  width="82"
                  height="42"
                  loading="lazy"
                />
              </li>
            )
        )}
        {partners.map(
          (partner) =>
            !partner.attributes.onTheTop && (
              <li key={partner.id}>
                <img
                  src={partner.attributes.image.data.attributes.url}
                  alt={`Logo da empresa ${partner.attributes.name}`}
                  width="82"
                  height="42"
                  loading="lazy"
                />
              </li>
            )
        )}
      </ul>
      <p>
        <strong>Mais de 140 parceiros</strong> impulsionam carreiras e
        transformam vidas junto da Kenzie. <strong> Vem com a gente! </strong>
      </p>
    </Container>
  );
}
