import { useRef } from "react";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import hero from "../../assets/img/bg-hero.png";
import heroMobile from "../../assets/img/bg-hero-mobile.png";
import PartnerCompany from "../../components/PartnerCompany";
import HowItWorksSection from "../../components/HowItWorksSection";
import DevsSection from "../../components/DevsSection";
import ProjectsSection from "../../components/ProjectsSection";
import FormSection from "../../components/FormSection";
import Footer from "../../components/Footer";
import PartnersSection from "../../components/PartnersSection";

export default function LandingPage() {
  const ref = useRef<HTMLDivElement>(null);

  function scrollToForm() {
    if (ref.current) {
      window.scrollTo({ top: ref.current.offsetTop - 100 });
    }
  }

  return (
    <>
      <Header onClick={scrollToForm} />
      <main>
        <Hero
          onClick={scrollToForm}
          image={heroMobile}
          imageLarge={hero}
          title="Seja uma empresa parceira da Kenzie"
          btnText="Quero ser parceira"
        >
          <p>
            Contrate os melhores programadores e programadoras de forma rápida e
            sem custo. Encontre profissionais que parecem feitos sob medida para
            a sua organização.
          </p>
        </Hero>
        <PartnerCompany />
        <HowItWorksSection onClick={scrollToForm} />
        <DevsSection />
        <PartnersSection />
        <ProjectsSection />
        <FormSection refProp={ref} />
      </main>
      <Footer />
    </>
  );
}
