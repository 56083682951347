import React, { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiUser, FiMail, FiPhone, FiAlertCircle } from "react-icons/fi";
import { IoIosBusiness } from "react-icons/io";
import { Container, Form, InputBox, ErrorMessage } from "./styles";
import HiddenInputs from "./hidden-inputs";
import arrow from "../../assets/img/arrow.svg";

interface Props {
  formId: number;
}

export default function ActiveCampaignForm({ formId }: Props) {
  const formatPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.maxLength = 15;
    event.target.value = event.target.value.replace(/\D/g, "");
    event.target.value = event.target.value.replace(
      /^(\d{2})(\d{4,5})(\d{4})/,
      "($1) $2-$3"
    );
  };

  const schema = yup.object().shape({
    fullname: yup.string().required(),
    email: yup.string().email().required("E-mail obrigatório"),
    phone: yup
      .string()
      .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/g, "Telefone inválido!")
      .required("Campo obrigatório!"),
    customer_account: yup.string().required(),
  });

  const {
    register,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const name = watch("fullname");
  const e_mail = watch("email");
  const phone_number = watch("phone");
  const customer = watch("customer_account");

  const validateEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const validatePhone = /\(\d{2}\)\s\d{4,5}-\d{4}/g;

  const onClickButton = (event: any) => {
    if (
      name === "" ||
      e_mail === "" ||
      phone_number === "" ||
      customer === "" ||
      !validateEmail.test(e_mail) ||
      !validatePhone.test(phone_number)
    ) {
      event.preventDefault();

      if (name === "") {
        setError("fullname", { message: "Preencha esse campo obrigatório." });
      }

      if (validateEmail.test(e_mail) === false) {
        setError("email", {
          message: "O e-mail deve ser formatado corretamente",
        });
      }

      if (e_mail === "") {
        setError("email", { message: "Preencha esse campo obrigatório." });
      }

      if (phone_number === "") {
        setError("phone", { message: "Preencha esse campo obrigatório." });
      }

      if (validatePhone.test(phone_number) === false) {
        setError("phone", {
          message: "Insira seu número com ddd. Exemplo 86988888888",
        });
      }

      if (customer === "") {
        setError("customer_account", {
          message: "Preencha esse campo obrigatório.",
        });
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (name !== null) {
      clearErrors("fullname");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (e_mail !== "" && validateEmail.test(e_mail)) {
      clearErrors("email");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_mail]);

  useEffect(() => {
    if (phone_number !== "" && validatePhone.test(phone_number) === true) {
      clearErrors("phone");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone_number]);

  useEffect(() => {
    if (customer !== null) {
      clearErrors("customer_account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const url = "https://kenzie39049.activehosted.com/proc.php";

  return (
    <Container>
      <Form
        onSubmit={onClickButton}
        action={url}
        method="POST"
        id={"_form_" + formId + "_"}
        className={"_form _form_" + formId + " _inline-form _dark"}
      >
        <HiddenInputs formId={formId} />

        <InputBox>
          {errors.fullname ? <FiAlertCircle color="red" /> : <FiUser />}

          <input
            id="fullname"
            {...register("fullname")}
            name="fullname"
            type="text"
            placeholder="Nome"
          />
        </InputBox>
        {errors.fullname && (
          <ErrorMessage>
            <span>{errors.fullname.message}</span>
          </ErrorMessage>
        )}
        <InputBox>
          {errors.email ? <FiAlertCircle color="red" /> : <FiMail />}
          <input
            id="email"
            {...register("email")}
            name="email"
            type="text"
            placeholder="E-mail"
          />
        </InputBox>
        {errors.email && (
          <ErrorMessage>
            <span>{errors.email.message}</span>
          </ErrorMessage>
        )}
        <InputBox>
          {errors.phone ? <FiAlertCircle color="red" /> : <FiPhone />}
          <input
            id="phone"
            {...register("phone")}
            name="phone"
            type="text"
            onChange={(e) => formatPhoneNumber(e)}
            placeholder={
              errors.phone ? "Insira DDD e número" : "Celular (com DDD)"
            }
          />
        </InputBox>
        {errors.phone && (
          <ErrorMessage>
            <span>{errors.phone.message}</span>
          </ErrorMessage>
        )}
        <InputBox>
          {errors.customer_account ? (
            <FiAlertCircle color="red" />
          ) : (
            <IoIosBusiness />
          )}
          <input
            id="customer_account"
            {...register("customer_account")}
            name="customer_account"
            type="text"
            placeholder="Nome da empresa"
          />
        </InputBox>
        {errors.customer_account && (
          <ErrorMessage>
            <span>{errors.customer_account.message}</span>
          </ErrorMessage>
        )}
        <button id="_form_27_submit" className="_submit" type="submit">
          <span>Inscreva-se!</span>
          <img src={arrow} alt="Seta" width="56" height="16" />
        </button>
      </Form>
    </Container>
  );
}
