import HowItWorksCard from "../HowItWorksCard";
import { Content } from "./styles";
import Title from "../Title";
import { Container } from "./styles";

interface HIWSProps {
  onClick: () => void;
}

export default function HowItWorksSection({ onClick }: HIWSProps) {
  return (
    <Container
      data-aos="fade-down"
      data-aos-duration="700"
      data-aos-easing="ease-out-cubic"
    >
      <Content>
        <Title>COMO FUNCIONA A PARCERIA?</Title>
        <ul>
          <HowItWorksCard
            backgroundColor="#FFFFFF"
            numberColor="#0000FF"
            cardNumber="1"
            paragraphColor="#2D2D2D"
            paragraph="Você se cadastra gratuitamente e entra em nossa base de parceiras oficiais."
          />
          <HowItWorksCard
            backgroundColor="#E1EBFF"
            numberColor="#0000FF"
            cardNumber="2"
            paragraphColor="#2D2D2D"
            paragraph="Compartilha com a Kenzie o perfil descritivo da vaga que você precisa fechar."
          />
          <HowItWorksCard
            backgroundColor="#6184FF"
            numberColor="#FFFFFF"
            cardNumber="3"
            paragraphColor="#FFFFFF"
            paragraph="Nós vamos selecionar os melhores devs de acordo com o perfil da sua vaga."
          />
          <HowItWorksCard
            backgroundColor="#0000FF"
            numberColor="#FFFFFF"
            cardNumber="4"
            paragraphColor="#FFFFFF"
            paragraph="O match entre Dev Kenzie & Empresa torna-se muito mais efetivo."
          />
        </ul>
        <button type="button" onClick={onClick}>
          Quero ser parceira
          <svg
            width="52"
            height="16"
            viewBox="0 0 52 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.2677 8.70711C51.6582 8.31659 51.6582 7.68342 51.2677 7.2929L44.9038 0.928936C44.5132 0.538411 43.8801 0.538411 43.4895 0.928936C43.099 1.31946 43.099 1.95262 43.4895 2.34315L49.1464 8L43.4895 13.6569C43.099 14.0474 43.099 14.6805 43.4895 15.0711C43.8801 15.4616 44.5132 15.4616 44.9038 15.0711L51.2677 8.70711ZM-9.40457e-08 9L50.5606 9L50.5606 7L9.40457e-08 7L-9.40457e-08 9Z"
              fill="#0000FF"
            />
          </svg>
        </button>
      </Content>
    </Container>
  );
}
