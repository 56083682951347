import { Container } from "./styles";

interface TitleProps {
  children: string;
  margin?: { marginMobile: number; marginDesk: number };
}

export default function Title({ children, margin }: TitleProps) {
  return <Container margin={margin}>{children}</Container>;
}
