import Title from "../Title";
import { Container, Content } from "./styles";
import CheckIcon from "../../assets/img/check-icon.png";

export default function PartnerCompany() {
  return (
    <Container
      data-aos="fade-left"
      data-aos-duration="700"
      data-aos-easing="ease-out-cubic"
    >
      <Content>
        <Title>por que ser uma empresa parceira?</Title>
        <ul>
          <li>
            <img src={CheckIcon} alt="Check icon" width="21" height="21" />
            Parceria com zero custos pra você e sua empresa.
          </li>
          <li>
            <img src={CheckIcon} alt="Check icon" width="21" height="21" />
            Reduza em 40% o tempo nos seus processos seletivos.
          </li>
          <li>
            <img src={CheckIcon} alt="Check icon" width="21" height="21" />
            Tenha a garantia de devs com alto comprometimento com hard skills e
            soft skills bem desenvolvidas.
          </li>
          <li>
            <img src={CheckIcon} alt="Check icon" width="21" height="21" />
            Ganhe assertividade: Nós vamos te ajudar a encontrar os melhores
            programadores.
          </li>
        </ul>
      </Content>
    </Container>
  );
}
