import { Wrapper, Container, Title, Content, Form } from "./styles";
import ActiveCampaignForm from "../ActiveCampaignForm";

interface FormSectionProps {
  refProp: React.RefObject<HTMLDivElement>;
}

export default function FormSection({ refProp }: FormSectionProps) {
  return (
    <Wrapper ref={refProp}>
      <Container>
        <Content>
          <Title>
            Faça parte desse time que está mudando a educação e tecnologia
          </Title>
          <p>
            Seja uma empresa parceira e contrate os melhores programadores do
            mercado - de forma 100% gratuita.
          </p>
          <p className="contact">
            <strong>Contato para parcerias</strong>: 41 99237 2908
          </p>
        </Content>
        <Form>
          <ActiveCampaignForm formId={27} />
        </Form>
      </Container>
    </Wrapper>
  );
}
