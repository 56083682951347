import styled from "styled-components";

interface ContainerProps {
  numberColor: string;
  paragraphColor: string;
  backgroundColor: string;
}

export const Container = styled.li<ContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 10px;
  height: 11.25rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 25%);
  span {
    display: block;
    color: ${({ numberColor }) => numberColor};
    font-size: 3.75rem;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: 800;
    margin-right: 1rem;
  }
  p {
    color: ${({ paragraphColor }) => paragraphColor};
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    height: 22rem;
    width: 16.5rem;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 8.125rem;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0;
    }
  }

  @media (min-width: 1280px) {
    height: 20rem;
  }
`;
