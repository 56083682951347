import { ReactNode } from "react";
import {
  Wrapper,
  BackgroundDiv,
  Container,
  ContentContainer,
  Title,
  Subtitle,
  Button,
} from "./styles";
import arrow from "../../assets/img/arrow.svg";

interface HeroProps {
  image: string;
  imageLarge?: string;
  title: string;
  isSubtitle?: boolean;
  onClick: () => void;
  btnText: string;
  children: ReactNode;
}

export default function Hero({
  image,
  title,
  onClick,
  btnText,
  children,
  isSubtitle = false,
  imageLarge = "",
}: HeroProps) {
  return (
    <Wrapper>
      <BackgroundDiv image={image} imageLarge={imageLarge}>
        <Container
          data-aos="fade-right"
          data-aos-duration="700"
          data-aos-easing="ease-out-cubic"
        >
          <ContentContainer>
            {!isSubtitle && <Title>{title}</Title>}
            {isSubtitle && <Subtitle>{title}</Subtitle>}
            {children}
            <Button onClick={onClick}>
              <span> {btnText}</span>
              <img src={arrow} alt="Seta" width="56" height="16" />
            </Button>
          </ContentContainer>
        </Container>
      </BackgroundDiv>
    </Wrapper>
  );
}
